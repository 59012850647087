.order-sim {
  padding: 5px;
}
.detail-sim {
  padding: 0px 20px;
  // margin: 10px;
  // border: 1px dashed var(--primary);
  border-radius: 10px;
  margin-bottom: 10px;
}
.detail-sim-1 {
  padding: 0px 10px;
  // margin: 10px;
  // border: 1px dashed var(--primary);
  border-radius: 10px;
  margin-bottom: 10px;
}
.detail-sim-title {
  font-size: 25px;
  font-weight: 700;
}
.detail-sim-title-1 {
  font-size: 15px;
}
.detail-sim-title-2 {
  font-size: 19px;
}
.detail-sim-title-b {
  font-weight: 600;
}
.order-sim-input {
  padding: 5px;
  line-height: 5;
}
.guide-order-sim {
  line-height: 2.5;
  font-size: 16px;
}
.price-old-item {
  text-decoration: line-through;
  color: #707070;
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}
.color-df {
  color: var(--blue-mb);
}
.color-blue {
  color: var(--blue);
}
.color-red {
  color: var(--primary);
}
.color-gold {
  color: var(--gold);
}

// chỉnh chữ phôi sim viettel
.container {
  position: relative;
  text-align: center;
  color: #303030;
  margin: 10px;
  canvas {
    width: 100%;
  }
}
.centered {
  position: absolute;
  top: 73%;
  left: 58%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  font-family: 600;
  font-weight: 600;
}
.download-image {
  position: absolute;
  bottom: 15px;
  right: 5px;
}
.note-love {
  font-size: 11px;
  color: #fff;
  background: #d22d32;
  padding: 2px;
  border-radius: 5px;
  margin-right: 5px;
}
.note-phamtramsale {
  font-size: 11px;
  color: #fff;
  background: red;
  padding: 3px;
  border-radius: 5px;
  margin-left: 5px;
}
.dis-flex-center {
  display: flex;
  align-items: center;
}
.frame-price {
  padding: 5px;
  border-radius: 5px;
}
.css-contents-shop {
  width: 100%;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
  color: #44494d;
  display: -webkit-box;
  font-size: 16px;
  line-height: 24px;
  max-height: 250px;
  overflow: auto;
  overflow-x: hidden;
  border: 2px solid var(--home-color);
  border-radius: 5px;
  padding: 5px;
  figure {
    width: 100% !important;
  }
  img {
    width: 100% !important;
    height: 100% !important;
  }
}
