th,
td {
  border: 1px solid #181818;
  border-collapse: collapse;
  padding: 0px 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-family: 'UTM-Avo';
}
// table {
//   border: 1px solid #181818;
// }
.dis-none {
  left: -9999px;
  position: absolute;
  top: -9999px;
}
// table red
.table-red {
  background: #fffee8;
  color: #ef0000;
  // border: 1px solid #ef0000;
  font-weight: bold;
  td {
    border: 1px solid #ef0000;
  }
}
.border-red {
  border: 1px solid #ef0000;
}
.th-red {
  background: #ef0000;
  color: #ffd536;
  border: 1px solid #ef0000;
}
.title-red {
  text-align: center;
  font-size: 20px;
  background: #ef0000;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-footer-red {
  text-align: center;
  font-size: 15px;
  background: #ef0000;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}
// table blue
.table-blue {
  background: #f7f7ff;
  color: #0600ef;
  // border: 1px solid #0600ef;
  font-weight: bold;
  td {
    border: 1px solid #0600ef;
  }
}
.border-blue {
  border: 1px solid #0600ef;
}
.th-blue {
  background: #0600ef;
  color: #ffe584;
  border: 1px solid #0600ef;
}
.title-blue {
  text-align: center;
  font-size: 20px;
  background: #0600ef;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-footer-blue {
  text-align: center;
  font-size: 15px;
  background: #0600ef;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}
// table green đậm
.table-green {
  background: #ecf9ff;
  color: #016066;
  // border: 1px solid #016066;
  font-weight: bold;
  td {
    border: 1px solid #016066;
  }
}
.border-green {
  border: 1px solid #016066;
}
.th-green {
  background: #016066;
  color: #ffe584;
  border: 1px solid #016066;
}
.title-green {
  text-align: center;
  font-size: 20px;
  background: #016066;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-footer-green {
  text-align: center;
  font-size: 15px;
  background: #016066;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}
// table đen
.table-black {
  background: #ffffff;
  color: #000f10;
  // border: 1px solid #000f10;
  font-weight: bold;
  td {
    border: 1px solid #000f10;
  }
}
.border-black {
  border: 1px solid #000f10;
}
.th-black {
  background: #000f10;
  color: #ffe584;
  border: 1px solid #000f10;
}
.title-black {
  text-align: center;
  font-size: 20px;
  background: #000f10;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-footer-black {
  text-align: center;
  font-size: 15px;
  background: #000f10;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}
// table cam
.table-origin {
  background: #fffbf7;
  color: #ea6a00;
  // border: 1px solid #ff8400;
  font-weight: bold;
  td {
    border: 1px solid #ff8400;
  }
}
.border-origin {
  border: 1px solid #ff8400;
}
.th-origin {
  background: #ff8400;
  color: #f5ff84;
  border: 1px solid #ff8400;
}
.title-origin {
  text-align: center;
  font-size: 20px;
  background: #ff8400;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title-footer-origin {
  text-align: center;
  font-size: 15px;
  background: #ff8400;
  color: #ffef5f;
  font-family: 'UTM-Avo';
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}
