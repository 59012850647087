.internet-tele__txt {
  -webkit-line-clamp: unset;
  overflow: auto;
  max-height: 80px;
  //   color: #44494d;
  //   font-size: 16px;
  //   line-height: 24px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}
