.tho {
  padding: 5px;
  background: #523c3e;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.kim {
  padding: 5px;
  background: #faae33;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.hoa {
  padding: 5px;
  background: #fb0013;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.moc {
  padding: 5px;
  background: #0d7a3d;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.thuy {
  padding: 5px;
  background: #ffffff;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
}
.bgr-img {
  background: url(../../assets/images/bgtim.png) #f4e6c9;
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
