.main-content-cate {
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  border: thin solid #eee;
}
.ml-10 {
  margin-left: 10px;
}
.mt-2 {
  margin-top: 2px !important;
}
.price-flash {
  color: gold;
  font-weight: 600;
}
.cs-countdow {
  background: #1f0000;
  color: white;
  border-radius: 4px;
  padding-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 2px;
  font-family: 'UTM-Avo';
  font-size: smaller;
}
.phoi-all {
  background: var(--home-color);
  border-radius: 10px;
  padding: 10px;
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
}
.cursor {
  cursor: pointer;
}
.phoi-all-1 {
  background: white;
  font-size: 21px;
  border: 1px dashed var(--home-color);
  margin-bottom: 5px;
  border-radius: 5px;
  // text-align: center;
  cursor: pointer;
  font-family: 'Messi';
  // letter-spacing: 1px;
  line-height: 1.4;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 5px;
  white-space: nowrap;
}
.cs-logo {
  font-size: 30px;
  color: #fff;
  font-family: 'Abril';
}
.cs-tieude {
  font-size: 11px;
  color: #fff900;
  text-align: center;
  margin-bottom: 5px;
}
.cs-color-white {
  color: white;
}
.css-load-sim {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 10px;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--home-color);
  width: 10px;
  height: 10px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.pl-1 {
  padding-left: 5px;
}
.p-5 {
  padding: 5px;
}
.fl-right {
  float: right;
  padding-top: 3px;
}
.fl-left {
  float: left;
  margin-top: -3px;
}
.dat-sim {
  font-size: 12px;
  background: red;
  color: #fff;
  border-radius: 5px;
  padding: 4px;
  margin-top: 11px;
  font-family: UTM-Avo;
}
.main-content-loadtheme {
  // padding: 0px 5px;
  border-radius: 12px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

// scoll
.box-list-subcate {
  overflow-x: scroll;
  padding-bottom: 5px;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #ddddddf0;
}
::-webkit-scrollbar-thumb:horizontal {
  background: var(--home-color);
  border-radius: 10px;
}
.list-subcate {
  width: max-content;
}
.box-list-subcate::-webkit-scrollbar {
  //   display: none;
}

.bgr-time {
  background: antiquewhite;
  border-radius: 10px;
}
