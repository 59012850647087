.internet-tele__txt {
  display: -webkit-box; /* Thiết lập kiểu hiển thị dạng box */
  -webkit-box-orient: vertical; /* Thiết lập hướng của box là dọc */
  -webkit-line-clamp: 3; /* Giới hạn số dòng hiển thị (trong ví dụ này là 2 dòng) */
  overflow: hidden; /* Ẩn những nội dung vượt quá khung hiển thị */
}
.color-red {
  color: red;
}
.internet-tele__txt_title {
  -webkit-line-clamp: unset;
  overflow: auto;
  max-height: 80px;
  //   color: #44494d;
  //   font-size: 16px;
  //   line-height: 24px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}
