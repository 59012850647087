// FAMER
.header-title-df {
  font-size: 15px;
  border: 1px solid var(--blue-mb);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--blue-mb);
  }
}
.header-title-item-df {
  background-color: var(--blue-mb);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.detail-item-sim {
  padding: 5px;
}
.detail-item-sim_1 {
  line-height: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}
.internet-tele__txt {
  -webkit-line-clamp: unset;
  overflow: auto;
  max-height: 95px;
  color: #44494d;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.font-size-small {
  font-size: 12px;
}
.font-size-medium {
  font-size: 16px;
}
.font-title-15 {
  font-size: 15px;
}
.font-18 {
  font-size: 18px;
}
.font-title-20 {
  font-size: 20px;
}
.dis-center {
  display: flex;
  align-items: center;
}
.title-item {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-item_1 {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
}
.border-radius-top-1 {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.svg_icons_ticker_df {
  transform: scale(0.7);
  color: var(--blue-mb);
}
img {
  -o-object-fit: cover;
  object-fit: cover;
}
// brg blue
.header-title-blue {
  font-size: 15px;
  border: 1px solid var(--blue);
  min-height: 65px;
  border-radius: 15px;
  margin: 5px;
  span b {
    color: var(--blue);
  }
}
.header-title-item-blue {
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.svg_icons_ticker_blue {
  transform: scale(0.7);
  color: var(--blue);
}
// brg gold
.header-title-gold {
  font-size: 15px;
  border: 1px solid var(--gold);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--gold);
  }
}
.header-title-item-gold {
  background-color: var(--gold);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.svg_icons_ticker_gold {
  transform: scale(0.7);
  color: var(--gold);
}

// brg green
.header-title-green {
  font-size: 15px;
  border: 1px solid var(--green);
  min-height: 65px;
  border-radius: 4px;
  margin: 5px;
  span b {
    color: var(--green);
  }
}
.header-title-item-green {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.svg_icons_ticker_green {
  transform: scale(0.7);
  color: var(--green);
}

// brg green
.header-title-red {
  font-size: 15px;
  border: 1px solid var(--primary);
  min-height: 185px;
  border-radius: 15px;
  margin: 5px;
  span b {
    color: var(--primary);
  }
}
.header-title-item-red {
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
}
.btn-item-sim {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.cat-chuoi {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.font-note {
  font-size: 13px;
  font-weight: bold;
  background: #39c0ff1c;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #46b2ff;
}
.color-blue {
  color: #0066c7;
}
@media (max-width: 991px) {
  .cat-chuoi {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .font-18 {
    font-size: 14px !important;
  }
  .font-note {
    font-size: 12px;
    font-weight: bold;
    background: #39c0ff1c;
    border-radius: 8px;
    padding: 5px;
    border: 1px solid #46b2ff;
  }
}
.container_red {
  position: relative;
  text-align: center;
  color: white;
}
.similar-pack__name {
  color: #fff;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: 'UTM-Avo';
  width: 100%;
}
.similar-pack__name_1 {
  color: #fff;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-family: 'FS';
  width: 100%;
}
.svg_icons_ticker_red {
  transform: scale(0.7);
  color: var(--primary);
}
.color-red {
  color: #d5001e;
}
.cut-str {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cs-countdow {
  background: red;
  color: white;
  border-radius: 4px;
  padding-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 2px;
  font-family: 'UTM-Avo';
  font-size: smaller;
}
.ml-10 {
  margin-left: 10px;
}
// detail goi cuoc
.pack-detail__left .pack-detail__endow .pack-detail__box {
  background-color: #dee6ee;
  border-radius: 16px 16px 0 0;
  padding: 12px;
  position: relative;
  border: 1px solid #dee6ee;
}

@media (max-width: 991px) {
  .pack-detail__box {
    border-radius: 8px;
  }
}
.pack-detail__box {
  background-color: #dee6ee;
  border-radius: 16px;
  padding: 12px 12px 15px;
  position: relative;
}
.pack-detail__box-inner {
  background-color: #fff;
  border-radius: 8px;
}
@media (max-width: 991px) {
  .pack-detail__box-top {
    padding: 8px 12px;
  }
}
.pack-detail__box-top {
  border-bottom: 2px dashed #dee6ee;
  padding: 8px 20px;
  position: relative;
}
.pack-detail__box-top:before {
  left: -8px;
}
.pack-detail__box-top:before,
.pack-detail__box-top:after {
  background-color: #dee6ee;
  border-radius: 50%;
  bottom: -8px;
  content: '';
  height: 16px;
  position: absolute;
  width: 16px;
}
@media (max-width: 991px) {
  .pack-detail__box-sub {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 30px;
    text-align: center;
  }
}
.pack-detail__box-sub {
  color: #44494d;
  font-family: 'FS';
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
.pack-detail__box-top:after {
  right: -8px;
}
.pack-detail__box-top:before,
.pack-detail__box-top:after {
  background-color: #dee6ee;
  border-radius: 50%;
  bottom: -8px;
  content: '';
  height: 16px;
  position: absolute;
  width: 16px;
}
// div:after {
//   content: '';
//   display: block;
//   clear: both;
// }
@media (max-width: 991px) {
  .pack-detail__box-inner .pack-detail__box-content {
    padding: 12px 18px 60px 12px;
  }
}
.pack-detail__box-inner .pack-detail__box-content {
  padding: 12px 10px 10px 20px;
}
@media (max-width: 991px) {
  .pack-detail__box-content {
    padding: 12px;
  }
}
.pack-detail__box-content {
  padding: 12px 40px 30px 20px;
}
@media (max-width: 991px) {
  .pack-detail__box-info {
    max-height: 250px;
  }
}
.pack-detail__box-info {
  max-height: 160px;
  overflow-y: auto;
}
.pack-detail__box p {
  line-height: 24px;
}
@media (max-width: 991px) {
  .pack-detail__box-des {
    color: #44494d;
    font-size: 14px;
    line-height: 20px;
  }
}
.pack-detail__box-des {
  color: #474747;
  font-size: 16px;
  line-height: 24px;
}
.pack-detail__box p {
  line-height: 24px;
}
