.wrapper-header {
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  background: url(../../../assets/images/175872.jpg) #bf0e12;
  padding-bottom: 5px;
  padding-top: 15px;
  background-size: cover;
  border-radius: 10px;
  //   margin-left: 5px;
  //   margin-right: 5px;
}

.inner-header {
  height: 100%;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--default-layout-horizontal-spacer);
}

.bgr-default {
  background: linear-gradient(180deg, #ff0000, #f95937 69%, #f99137);
}
.color-trang {
  color: #fff;
  font-size: 13px;
  padding-top: 10px;
}
.font-fs {
  font-family: FS;
}
.font-avo {
  font-family: UTM-Avo;
}
.fs-20 {
  font-size: 20px;
}
.fs-16 {
  font-size: 16px;
}
.bg-xanhlam {
  background-color: #1b63b9;
  color: #f7de92;
}
