.wrapper {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  // box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  // background: linear-gradient(-180deg, #d70018, #f63);
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
.wrapper-notifi {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  // box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  // background: linear-gradient(-180deg, #d70018, #f63);
  display: flex;
  justify-content: center;
  background-color: #a60000;
  padding-bottom: 6px;
}
.notifi {
  color: #ffe987;
  padding: 3px;
  margin-top: 5px;
  font-size: 14px;
  cursor: pointer;
}
@keyframes ping {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.ping {
  width: 10px;
  height: 10px;
  background-color: gold;
  border-radius: 50%;
  margin-top: 15px;
  margin-right: 2px;
  animation: ping 1s ease-in-out infinite;
}
.inner {
  height: 100%;
  width: var(--default-layout-width);
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--default-layout-horizontal-spacer);
}

.logo-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-left-md {
  display: flex;
  align-items: center;
}
.logo-right-md {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px !important;
}
.logo-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo-link {
  display: flex;
}

.more-btn {
  font-size: 2rem;
  margin-left: 28px;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
}

//Menu
.menu-items {
  width: 224px;
}
.logo {
  height: 40px;
  border-radius: 10px;
  object-fit: contain;
  margin-right: 5px;
  padding: 3px;
  background: #e92002;
  margin-top: 8px;
}
.title-note-sim {
  color: aliceblue;
  padding: 5px;
}
.color-trang {
  color: #fff;
  font-size: 13px;
  padding-top: 10px;
}
.color-logo-title {
  color: #e92002;
  font-size: 28px;
  padding-top: 10px;
  text-shadow: 2px 2px #999999db;
  text-transform: uppercase;
  font-family: 'Abril';
  text-align: center;
}
.color-hotline {
  color: #fff;
  align-items: center;
  font-size: 16px;
}
.color-hotline-xs {
  color: #fff;
  align-items: center;
  font-size: 14px;
}
.color-gold {
  color: gold;
}
.color-black {
  color: #444444;
}
.dis-flex {
  display: flex;
}
.color-red {
  color: #d90000;
}
