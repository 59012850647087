.header-title-grey-layout2 {
  font-size: 15px;
  border: thin solid #eee;
  // min-height: 65px;
  border-radius: 10px;
  margin: 5px;
  margin-bottom: 15px;
  background: #fff;
  span b {
    color: var(--home-color);
  }
}
.header-title-item-grey-layout2 {
  background-color: #f9f9f9;
  border-bottom: thin solid #eee;
  display: flex;
  padding: 5px;
  // color: #ffffff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.title-sim-new {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 2px;
  margin-bottom: 0px;
  font-weight: 600;
  font-family: 'Abril';
  color: var(--home-color);
  border-bottom: 1px solid;
  justify-content: space-between;
}
.dis-center {
  display: flex;
  align-items: center;
}
.pl-1 {
  padding-left: 5px;
}
.css-load-sim {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 10px;
}
