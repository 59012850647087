.wrapper {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  // box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  // background: linear-gradient(-180deg, #d70018, #f63);
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 6px;
}

.wrapper-layout2 {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  // box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  // background: linear-gradient(-180deg, #d70018, #f63);
  display: flex;
  justify-content: center;
  background-color: black;
  padding-bottom: 6px;
}

.wrapper-layout2-mobi {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  // box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  // background: linear-gradient(-180deg, #d70018, #f63);
  display: flex;
  justify-content: center;
  background-color: var(--home-color);
  padding-bottom: 6px;
}

.is-sticky-pc {
  position: fixed;
  top: 0;
  width: 100%;
}
.is-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.2s ease;
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.wrapper-menu-layout2 {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  background-color: var(--home-color);
  padding-bottom: 6px;
}

.wrapper-app {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  background-color: black;
  padding-bottom: 6px;
}
.wrapper-notifi {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  background-color: var(--home-color);
  padding-bottom: 6px;
}

.wrapper-notifi-layout2 {
  --search-height: 35px;
  --search-top-spacer: 5px;
  --search-border-radius: 92px;
  --search-button-width: 52px;

  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // height: var(--default-layout-header-height);
  // box-shadow: 0px 1px 1px RGB(0 0 0 / 12%);
  // background: linear-gradient(-180deg, #d70018, #f63);
  display: flex;
  padding-top: 5px;
}

.brg-df {
  background-color: var(--home-color);
}
.notifi {
  color: #ffe987;
  padding: 3px;
  margin-top: 5px;
  font-size: 14px;
  cursor: pointer;
}

.notifi-layout2 {
  color: #ffe987;
  padding: 3px;
  margin-top: 5px;
  font-size: 13px;
  cursor: pointer;
}
@keyframes ping {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.ping {
  width: 10px;
  height: 10px;
  background-color: gold;
  border-radius: 50%;
  margin-top: 15px;
  margin-right: 2px;
  animation: ping 1s ease-in-out infinite;
}
.ping-layout2 {
  width: 10px;
  height: 10px;
  background-color: gold;
  border-radius: 50%;
  margin-top: 13px;
  margin-right: 2px;
  animation: ping 1s ease-in-out infinite;
}
.inner {
  height: 100%;
  width: var(--default-layout-width);
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--default-layout-horizontal-spacer);
}

.logo-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-left-md {
  display: flex;
  align-items: center;
}
.logo-right-md {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px !important;
}
.logo-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo-link {
  display: flex;
}

.more-btn {
  font-size: 2rem;
  margin-left: 28px;
  padding: 4px 8px;
  background-color: transparent;
  cursor: pointer;
}

.bgr-default {
  background: linear-gradient(180deg, #ff0000, #f95937 69%, #f99137);
}
.color-white {
  color: #fff;
}
.filter-color {
  filter: hue-rotate(var(--home-color-deg));
}

//Menu
.menu-items {
  width: 224px;
}
.logo {
  height: 40px;
  border-radius: 10px;
  object-fit: contain;
  margin-right: 5px;
  padding: 3px;
  background: #e92002;
  margin-top: 8px;
  filter: hue-rotate(var(--home-color-deg));
}
.logo-layout2 {
  height: 26px;
  border-radius: 15px;
  object-fit: contain;
  margin-right: 5px;
  padding: 4px;
  background: #e92002;
  margin-top: 6px;
  filter: hue-rotate(var(--home-color-deg));
}

.logo-layout2-mobi {
  height: 26px;
  border-radius: 15px;
  object-fit: contain;
  margin-right: 5px;
  padding: 3px;
  background: #e92002;
  margin-top: 6px;
  filter: hue-rotate(var(--home-color-deg));
  border: 1px solid #fff;
}
.title-note-sim {
  color: aliceblue;
  padding: 5px;
}
.color-trang {
  color: #fff;
  font-size: 13px;
  padding-top: 10px;
}
.color-logo-title {
  color: var(--home-color);
  font-size: 26px;
  padding-top: 10px;
  // text-shadow: 2px 2px #ff9d00;
  font-family: 'Abril';
}
.color-logo-title-layout2-mobi {
  color: var(--home-color);
  font-size: 26px;
  // padding-top: 10px;
  // text-shadow: 2px 2px #ff9d00;
  font-family: 'Abril';
}

.color-logo-title-layout2 {
  color: #fff;
  font-size: 15px;
  padding-top: 10px;
  // text-shadow: 2px 2px #ff9d00;
  font-family: 'Abril';
}
.color-hotline {
  color: #fff;
  align-items: center;
  font-size: 16px;
}
.color-hotline-layout2 {
  color: #fff;
  align-items: center;
  font-size: 15px;
}
.color-hotline-xs {
  color: #fff;
  align-items: center;
  font-size: 14px;
}
.color-gold {
  color: gold;
}
.color-black {
  color: #444444;
}
.dis-flex {
  display: flex;
}
.color-red {
  color: var(--home-color);
}
// .color-trang a {
//   position: relative;
//   &::before {
//     left: -5%;
//     animation: light-left 2.5s infinite alternate linear;
//   }
//   &::after {
//     right: -5%;
//     animation: light-right 2.5s infinite alternate linear;
//   }
// }
// .color-trang a:after,
// .color-trang a:before {
//   content: '';
//   position: absolute;
//   top: 50%;
//   width: 10px;
//   height: 100%;
//   transform: translateY(-50%);
//   background-color: rgba(255, 255, 255, 0.75);
//   z-index: 999999999;
// }
.menu {
  padding: 5px 10px;
  color: white;
}
