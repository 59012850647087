.order-sim {
  padding: 5px;
}
.detail-sim {
  padding: 10px 20px;
  margin: 10px;
  border: 1px dashed var(--primary);
  border-radius: 10px;
}
.detail-sim-title {
  font-size: 25px;
  font-weight: 700;
}
.detail-sim-title-1 {
  font-size: 15px;
}
.detail-sim-title-b {
  font-weight: 600;
}
.order-sim-input {
  padding: 5px;
  line-height: 5;
}
.guide-order-sim {
  line-height: 2.5;
  font-size: 16px;
}
.price-old-item {
  text-decoration: line-through;
  color: #707070;
  font-size: 18px;
  font-weight: 600;
}
.color-df {
  color: var(--blue-mb);
}
.color-blue {
  color: var(--blue);
}
.color-red {
  color: var(--primary);
}

// chỉnh chữ phôi sim viettel
.container {
  position: relative;
  text-align: center;
  color: #303030;
  margin: 10px;
  canvas {
    width: 100%;
  }
}
.centered {
  position: absolute;
  top: 73%;
  left: 58%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  font-family: 600;
  font-weight: 600;
}
.download-image {
  position: absolute;
  bottom: 15px;
  right: 5px;
}
