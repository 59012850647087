.header-title-df {
  font-size: 15px;
  border: 1px solid var(--blue-mb);
  min-height: 65px;
  border-radius: 10px;
  margin: 5px;
  span b {
    color: var(--blue-mb);
  }
}
.header-title-item-df {
  background-color: var(--blue-mb);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.detail-item-sim {
  padding: 5px;
}
.font-size-small {
  font-size: 12px;
}
.font-size-medium {
  font-size: 16px;
}
.font-title-15 {
  font-size: 15px;
}
.font-title-20 {
  font-size: 20px;
}
.dis-center {
  display: flex;
  align-items: center;
}
.title-item {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
}
.svg_icons_ticker_df {
  transform: scale(0.7);
  color: var(--blue-mb);
}
// brg blue
.header-title-blue {
  font-size: 15px;
  border: 1px solid var(--blue);
  min-height: 65px;
  border-radius: 10px;
  margin: 5px;
  span b {
    color: var(--blue);
  }
}
.header-title-item-blue {
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.svg_icons_ticker_blue {
  transform: scale(0.7);
  color: var(--blue);
}
// brg gold
.header-title-gold {
  font-size: 15px;
  border: 1px solid var(--gold);
  min-height: 65px;
  border-radius: 10px;
  margin: 5px;
  span b {
    color: var(--gold);
  }
}
.header-title-item-gold {
  background-color: var(--gold);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.svg_icons_ticker_gold {
  transform: scale(0.7);
  color: var(--gold);
}

// brg green
.header-title-green {
  font-size: 15px;
  border: 1px solid var(--green);
  min-height: 65px;
  border-radius: 10px;
  margin: 5px;
  span b {
    color: var(--green);
  }
}
.header-title-item-green {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.svg_icons_ticker_green {
  transform: scale(0.7);
  color: var(--green);
}

// brg green
.header-title-red {
  font-size: 15px;
  border: 1px solid var(--primary);
  min-height: 65px;
  border-radius: 10px;
  margin: 5px;
  span b {
    color: var(--primary);
  }
}
.header-title-item-red {
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  padding: 5px;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.svg_icons_ticker_red {
  transform: scale(0.7);
  color: var(--primary);
}
.color-red {
  color: #d5001e;
}
.cut-str {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// css image shop
.title-css {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'UTM-Avo';
  font-size: 1rem;
  font-weight: bold;
  height: 45px;
}
.price-item {
  color: crimson;
  font-weight: 600;
  font-size: 18px;
  font-family: 'UTM-Avo';
}
.price-old-item {
  font-weight: 600;
  font-size: 13px;
  font-family: 'UTM-Avo';
  text-decoration: line-through;
  color: #707070;
}
.font-size-tool-sale {
  font-size: 14px;
  font-family: 'UTM-Avo';
}
.font-size-tool-sale-icon {
  transform: scale(0.7);
}
.space-icon-sale {
  display: flex;
  align-items: center;
  color: crimson;
}
.bt {
  border-top: 1px solid #cccccc4d;
}
.h-50 {
  height: 50px;
}
