.call-animation {
  margin: 0 auto;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 3px rgba(127, 168, 255, 0.4);
  }
  25% {
    box-shadow: 0 0 0 6px rgba(127, 168, 255, 0.4), 0 0 0 12px rgba(20, 4, 243, 0.2);
  }
  25% {
    box-shadow: 0 0 0 9px rgba(127, 168, 255, 0.4), 0 0 0 15px rgba(20, 4, 243, 0.2);
  }
}

.call-animation-red {
  margin: 0 auto;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  animation: play-red 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes play-red {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 3px rgba(255, 127, 127, 0.4);
  }
  25% {
    box-shadow: 0 0 0 6px rgba(255, 127, 127, 0.4), 0 0 0 12px rgba(243, 4, 4, 0.2);
  }
  25% {
    box-shadow: 0 0 0 9px rgba(255, 127, 127, 0.4), 0 0 0 15px rgba(243, 4, 4, 0.2);
  }
}
.img-m {
  margin-top: -7px !important;
  margin-left: -7px !important;
}
.h-w-45 {
  width: 45px !important;
  height: 45px !important;
}
