.text-hoa {
  text-transform: uppercase;
}
.news_internet-tele__txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.title-tt {
  font-size: 20px;
  padding-top: 10px;
  font-family: 'Abril';
}
// p img {
//   width: 100%;
// }
h1 {
  font-size: 1.5em;
}
h2 {
  font-size: 1.2em;
}
.img {
  max-width: 100%;
  max-height: 300px;
  -webkit-box-shadow: 0 0 13px 3px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 13px 3px rgba(0, 0, 0, 1);
  box-shadow: 0 0 13px 3px rgba(0, 0, 0, 1);
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  p img {
    max-width: 100%;
    height: auto;
  }
  li img {
    max-width: 100%;
    height: auto;
  }
}
