.fix-remove {
  //   border: 1px solid #dddddd;
  //   width: 200px;
  //   height: 200px;
  //   position: relative;
}
.tag {
  float: left;
  position: relative;
  left: 0px;
  top: 0px;
  background-color: red;
  z-index: 1000;
  margin-bottom: -20px;
  color: #fff;
  padding: 0px 5px;
  font-weight: bold;
  cursor: pointer;
}
th,
td {
  border: 0;
  border-collapse: collapse;
  padding: 0px 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-family: 'UTM-Avo';
}
