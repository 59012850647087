.wrapper {
  padding-top: 80px;
  padding-bottom: 20px;
  //   margin-right: 15px;
  padding-right: 15px;
  background-color: white;
}

.text-title {
  padding-left: 10px;
  font-size: 14px;
}
.text-title-loading {
  padding-left: 10px;
  font-size: 18px;
  color: red;
}
