// Calender
.calendar {
  width: 100%;
  margin: 10px auto 0;
  box-shadow: 1px 2px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.dis-center {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px 6px 0 0;
  background-color: #1abc9c;
  color: #fff;
  font-family: UTM-Avo;
}

.nav {
  width: 30px;
  //   height: 30px;
  border: 1px solid #fff;
  border-radius: 99px;
  outline: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-family: UTM-Avo;
  padding-bottom: 5px;
}

.datetime {
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
  font-family: UTM-Avo;
}

.week-container,
.day-container {
  display: flex;
  flex-wrap: wrap;
}

.week-cell,
.day-cell {
  flex: 0 0 calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border: 1px dotted #ccc;
  //   background: #fffeea;
  box-sizing: border-box;
  font-family: UTM-Avo;
  border-radius: 4px;
}
.bgr-week {
  background: #f0f0f0;
}

.day-cell--faded {
  opacity: 0.4;
}

.day-celltoday {
  border-radius: 99px;
  background-color: #1abc9c;
  color: #fff;
}
.note-price {
  color: red;
  font-size: 15px;
}
.dis-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.active-day {
  background: #fffbdc;
}
.ds-color {
  color: #1abc9c;
  white-space: nowrap;
  font-size: 20px;
}
.ct-color {
  color: red;
  white-space: nowrap;
  font-size: 20px;
}
@media only screen and (max-width: 480px) {
  .note-price {
    font-size: 13px;
  }
  .ds-color {
    font-size: 15px;
  }
  .ct-color {
    font-size: 15px;
  }
}
.font-avo {
  font-family: UTM-Avo;
}
.cus-detail-name {
  background: #eee;
  padding: 0px 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
}
.cus-detail-name-1 {
  border-bottom: 1px solid #ccc;
  padding: 5px 5px;
  // border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
}
.list-subcate {
  width: max-content;
}
.box-list-subcate::-webkit-scrollbar {
  display: none;
}

.box-list-subcate {
  overflow-x: scroll;
}

.chart-report {
  background: #ebfffb;
  border-radius: 10px;
  padding-bottom: 3px;
  border: 1px dotted #1abc9c;
}
