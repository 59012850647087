.header {
  //   background: linear-gradient(90deg, var(--home-color), #f63);
  color: #fff;
  padding: 20px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nowrap {
  white-space: nowrap;
}
.font-family-1 {
  font-family: 'Abril';
}
.dis-center {
  display: flex;
  align-items: center;
}
.thongke {
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
}
.frameImage {
  padding: 10px;
  border: 1px dashed #ccc;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.dis-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
