@import '../../assets/fonts/UTM-Avo.ttf';
@import '../../assets/fonts/FS-Magistral-Bold.ttf';
@import '../../assets/fonts/ImprintMTShadow.ttf';
@import '../../assets/fonts/SVN-AbrilFatface.ttf';
@import '../../assets/fonts/VCENTB.TTF';
@import '../../assets/fonts/UTM-Impact.ttf';
@import '../../assets/fonts/barcenonal.ttf';
@import '../../assets/fonts/Anton-Regular.ttf';
@import '../../assets/fonts/VNF-Comic-Sans.ttf';
// @import '../../assets/fonts/JohnHancockCP.otf';
@font-face {
  font-family: 'UTM-Avo';
  src: local('UTM-Avo'), url('../../assets/fonts/UTM-Avo.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'UTM';
  src: local('UTM'), url('../../assets/fonts/UTM-Avo.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'FS';
  src: local('FS'), url('../../assets/fonts/FS-Magistral-Bold.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'Messi';
  src: local('Messi'), url('../../assets/fonts/ImprintMTShadow.ttf') format('truetype');
  font-style: italic;
  // font-weight: bold;
}
@font-face {
  font-family: 'Abril';
  src: local('Abril'), url('../../assets/fonts/SVN-AbrilFatface.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'VCENTB';
  src: local('VCENTB'), url('../../assets/fonts/VCENTB.TTF') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'Impact';
  src: local('Impact'), url('../../assets/fonts/UTM-Impact.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'fantasy';
  src: local('fantasy'), url('../../assets/fonts/UTM-Impact.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'barcenonal';
  src: local('barcenonal'), url('../../assets/fonts/barcenonal.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'Anton';
  src: local('Anton'), url('../../assets/fonts/Anton-Regular.ttf') format('truetype');
  // font-weight: bold;
}
@font-face {
  font-family: 'Comic Sans MS';
  src: local('Comic Sans MS'), url('../../assets/fonts/VNF-Comic-Sans.ttf') format('truetype');
  // font-weight: bold;
}
// @font-face {
//   font-family: 'John';
//   src: local('John'), url('../../assets/fonts/JohnHancockCP.otf') format('opentype');
//   // font-weight: bold;
// }
:root {
  --primary: #d70018;
  --black: #000;
  --text-color: #333;
  --white: white;
  --blue: #229fd9;
  --green: #00918c;
  --gold: #eb8400;
  --blue-mb: #0967c5;
  --home-color: #bb0000;
  --home-color-deg: 0deg;

  //Default Layout
  --default-layout-header-height: 60px;
  --default-layout-width: 1150px;
  --default-layout-horizontal-spacer: 10px;

  --bgr-dark: #828282;
  --bgr-dark-frame: #000;
  --bgr-dark-frame-color: white;
  --bgr-light: white;
}

* {
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  font-family: UTM-Avo;
  font-size: 13px;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  color: var(--text-color);
  overflow-y: overlay;
  overflow-x: hidden;
  background-color: #f5f5f5;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 5px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
}
// Fonts

@font-face {
  font-family: ProximaNova;
  src: url('/assets/fonts/Proxima-Nova-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: ProximaNova;
  src: url('/assets/fonts/Proxima-Nova-Semibold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: ProximaNova;
  src: url('/assets/fonts/ProximaNova-Semibold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: SofiaPro;
  src: url('/assets/fonts/sofiapro-semibold.otf');
  font-weight: 700;
}

.dark {
  background-color: var(--bgr-dark-frame) !important;
  color: var(--bgr-dark-frame-color) !important;
}
